
/*=================  
	Basic 
====================*/
body {
    background-color: #fff;
    font-family: 'CerebriSans';
    font-size: 16px;
    font-weight: 400;
    color:#585e6d;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}
a { color: #6f6f6f; outline: 0 none; text-decoration: none; }
a:hover, a:focus { text-decoration: none; outline: 0 none; }
a:active, a:hover { color: #333333; }
p a { color: #333333; }
img { border-style: none; height: auto; max-width: 100%; vertical-align: middle; }
hr { clear: both; }
section, article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, summary { display: block; }
address { font-style: italic; }
table { background-color: transparent;  width: 100%; }
table thead th { color: #333; }
table td { padding: 15px 10px; }
p, address, pre, hr, ul, ol, dl, dd, table { margin-bottom: 24px; }
::selection { color: #000; background:#dbdbdb; }
::-moz-selection { color:#000; background:#dbdbdb; }
::-moz-selection { background: #3396d1; color: #fff; }
::selection { background: #3396d1; color: #fff; }
p { line-height: 26px; margin-bottom: 24px; }
iframe{border:0;}
@media only screen and (max-width: 991px) {
	body {
		font-size: 15px;
	}
	p{
		line-height: 24px;
	}
}

/*=================  
	Headings
====================*/
h1, h2, h3, h4, h5, h6 { color: #111111; font-family: 'Manrope', sans-serif; margin-top:0; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 {font-size: 42px; line-height: 1.2; margin-bottom: 25px; font-weight: 800; }
h2 {font-size: 36px; line-height: 1.2; margin-bottom: 25px; font-weight: 800; }
h3 {font-size: 28px; line-height: 1.3; margin-bottom: 20px; font-weight: 800; }
h4 {font-size: 24px; line-height: 1.3; margin-bottom: 15px; font-weight: 700; }
h5 {font-size: 20px; line-height: 1.4; margin-bottom: 10px; font-weight: 700; }
h6 {font-size: 18px; line-height: 1.4; margin-bottom: 10px; font-weight: 700; }
@media only screen and (max-width: 991px) {
	h1{font-size: 32px;}
	h2{font-size: 28px; line-height: 1.3;}
	h3{font-size: 24px;}
	h4{font-size: 22px;}
	h5{font-size: 20px;}
	h6{font-size: 18px;}
}

/*=================  
	Font Size
====================*/
.fs10 { font-size: 10px; }
.fs12 { font-size: 12px; }
.fs13 { font-size: 13px; }
.fs14 { font-size: 14px; }
.fs16 { font-size: 16px; }
.fs18 { font-size: 18px; }
.fs20 { font-size: 20px; }
.fs22 { font-size: 22px; }
.fs24 { font-size: 24px; }
.fs26 { font-size: 26px; }
.fs30 { font-size: 30px; line-height:40px; }
.fs35 { font-size: 35px; line-height:45px; }
.fs40 { font-size: 40px; line-height:50px; }
.fs45 { font-size: 45px; line-height:55px; }
.fs50 { font-size: 50px; line-height:65px; }
.fs55 { font-size: 55px; line-height:65px; }
.fs60 { font-size: 60px; line-height:70px; }
.fs70 { font-size: 70px; line-height:80px; }
.fs75 { font-size: 75px; line-height:85px; }
.fs80 { font-size: 80px; line-height:90px; }
.fs90 { font-size: 90px;	line-height:100px; }
.fs100 { font-size: 100px; line-height:110px; }
/*=================  
	Font Weight
====================*/
.fw3 { font-weight: 300; }
.fw4 { font-weight: 400; }
.fw5 { font-weight: 500; }
.fw6 { font-weight: 600; }
.fw7 { font-weight: 700; }
.fw8 { font-weight: 800; }
.fw9 { font-weight: 900; }
/*=================  
	LISTS
====================*/
dl, ul, ol { list-style-position: outside; padding: 0; }
ul, ol { margin-bottom: 24px; }
ul li, ol li { padding: 0; }
dl { margin-left: 0; margin-bottom: 30px; }
dl dd { margin-bottom: 10px; }
/*=================  
	Padding Around
====================*/
.p-a0 { padding: 0;}
.p-a5 { padding: 5px; }
.p-a10 { padding: 10px;}
.p-a15 { padding: 15px; }
.p-a20 { padding: 20px; }
.p-a25 { padding: 25px; }
.p-a30 { padding: 30px; }
.p-a40 { padding: 40px; }
.p-a50 { padding: 50px; }
.p-a60 { padding: 60px; }
.p-a70 { padding: 70px; }
.p-a80 { padding: 80px; }
.p-a90 { padding: 90px; }
.p-a100 { padding: 100px; }
/*=================  
	Padding Top
====================*/
.p-t0 { padding-top: 0; }
.p-t5 { padding-top: 5px; }
.p-t10 { padding-top: 10px; }
.p-t15 { padding-top: 15px; }
.p-t20 { padding-top: 20px; }
.p-t30 { padding-top: 30px; }
.p-t40 { padding-top: 40px; }
.p-t50 { padding-top: 50px; }
.p-t60 { padding-top: 60px; }
.p-t70 { padding-top: 70px; }
.p-t80 { padding-top: 80px; }
.p-t90 { padding-top: 90px; }
.p-t100 { padding-top: 100px; }
/*=================  
	Padding Bottom
====================*/
.p-b0 { padding-bottom: 0; }
.p-b5 { padding-bottom: 5px; }
.p-b10 { padding-bottom: 10px; }
.p-b15 { padding-bottom: 15px; }
.p-b20 { padding-bottom: 20px; }
.p-b30 { padding-bottom: 30px; }
.p-b40 { padding-bottom: 40px; }
.p-b50 { padding-bottom: 50px; }
.p-b60 { padding-bottom: 60px; }
.p-b70 { padding-bottom: 70px; }
.p-b80 { padding-bottom: 80px; }
.p-b90 { padding-bottom: 90px; }
.p-b100 { padding-bottom: 100px; }
/*=================  
	Padding Left
====================*/
.p-l0 { padding-left: 0; }
.p-l5 { padding-left: 5px; }
.p-l10 { padding-left: 10px; }
.p-l15 { padding-left: 15px; }
.p-l20 { padding-left: 20px; }
.p-l30 { padding-left: 30px; }
.p-l40 { padding-left: 40px; }
.p-l50 { padding-left: 50px;}
.p-l60 { padding-left: 60px; }
.p-l70 { padding-left: 70px; }
.p-l80 { padding-left: 80px; }
.p-l90 { padding-left: 90px; }
.p-l100 { padding-left: 100px; }
/*=================  
	Padding Right
====================*/
.p-r0 { padding-right: 0;}
.p-r5 { padding-right: 5px; }
.p-r10 { padding-right: 10px;}
.p-r15 { padding-right: 15px; }
.p-r20 { padding-right: 20px; }
.p-r30 { padding-right: 30px; }
.p-r40 { padding-right: 40px; }
.p-r50 { padding-right: 50px; }
.p-r60 { padding-right: 60px; }
.p-r70 { padding-right: 70px;}
.p-r80 { padding-right: 80px; }
.p-r90 { padding-right: 90px; }
.p-r100 { padding-right: 100px;}
/*=================  
	Padding Left Right
====================*/
.p-lr0 { padding-left: 0; padding-right: 0; }
.p-lr5 { padding-left: 5px; padding-right: 5px; }
.p-lr10 { padding-left: 10px; padding-right: 10px; }
.p-lr15 { padding-left: 15px; padding-right: 15px; }
.p-lr20 { padding-left: 20px; padding-right: 20px; }
.p-lr30 { padding-left: 30px; padding-right: 30px; }
.p-lr40 { padding-left: 40px; padding-right: 40px; }
.p-lr50 { padding-left: 50px; padding-right: 50px; }
.p-lr60 { padding-left: 60px; padding-right: 60px; }
.p-lr70 { padding-left: 70px; padding-right: 70px; }
.p-lr80 { padding-left: 80px; padding-right: 80px; }
.p-lr90 { padding-left: 90px; padding-right: 90px; }
.p-lr100 { padding-left: 100px; padding-right: 100px; }
/*=================  
	Padding Top Bottom
====================*/
.p-tb0 { padding-bottom: 0; padding-top: 0; }
.p-tb5 { padding-bottom: 5px; padding-top: 5px; }
.p-tb10 { padding-bottom: 10px; padding-top: 10px; }
.p-tb15 { padding-bottom: 15px; padding-top: 15px; }
.p-tb20 { padding-bottom: 20px; padding-top: 20px; }
.p-tb30 { padding-bottom: 30px; padding-top: 30px; }
.p-tb40 { padding-bottom: 40px; padding-top: 40px; }
.p-tb50 { padding-bottom: 50px; padding-top: 50px; }
.p-tb60 { padding-bottom: 60px; padding-top: 60px; }
.p-tb70 { padding-bottom: 70px; padding-top: 70px; }
.p-tb80 { padding-bottom: 80px; padding-top: 80px; }
.p-tb90 { padding-bottom: 90px; padding-top: 90px; }
.p-tb100 { padding-bottom: 100px; padding-top: 100px; }
/*=================  
	Margin Around
====================*/
.m-auto { margin-left:auto; margin-right:auto;}
.m-a0 { margin: 0;}
.m-a5 { margin: 5px; }
.m-a10 { margin: 10px;}
.m-a15 { margin: 15px; }
.m-a20 { margin: 20px; }
.m-a25 { margin: 25px; }
.m-a30 { margin: 30px; }
.m-a40 { margin: 40px; }
.m-a50 { margin: 50px; }
.m-a60 { margin: 60px; }
.m-a70 { margin: 70px; }
.m-a80 { margin: 80px; }
.m-a90 { margin: 90px; }
.m-a100 { margin: 100px; }
/*=================  
	Margin Top
====================*/
.m-t0 { margin-top: 0; }
.m-t5 { margin-top: 5px; }
.m-t10 { margin-top: 10px; }
.m-t15 { margin-top: 15px; }
.m-t20 { margin-top: 20px; }
.m-t30 { margin-top: 30px; }
.m-t40 { margin-top: 40px; }
.m-t50 { margin-top: 50px; }
.m-t60 { margin-top: 60px; }
.m-t70 { margin-top: 70px; }
.m-t80 { margin-top: 80px; }
.m-t90 { margin-top: 90px; }
.m-t100 { margin-top: 100px; }
/*=================  
	Margin Bottom
====================*/
.m-b0 { margin-bottom: 0; }
.m-b5 { margin-bottom: 5px; }
.m-b10 { margin-bottom: 10px; }
.m-b15 { margin-bottom: 15px; }
.m-b20 { margin-bottom: 20px; }
.m-b30 { margin-bottom: 30px; }
.m-b40 { margin-bottom: 40px; }
.m-b50 { margin-bottom: 50px; }
.m-b60 { margin-bottom: 60px; }
.m-b70 { margin-bottom: 70px; }
.m-b80 { margin-bottom: 80px; }
.m-b90 { margin-bottom: 90px; }
.m-b100 { margin-bottom: 100px; }
/*=================  
	Margin Left
====================*/
.m-l0 { margin-left: 0; }
.m-l5 { margin-left: 5px; }
.m-l10 { margin-left: 10px; }
.m-l15 { margin-left: 15px; }
.m-l20 { margin-left: 20px; }
.m-l30 { margin-left: 30px; }
.m-l40 { margin-left: 40px; }
.m-l50 { margin-left: 50px;}
.m-l60 { margin-left: 60px; }
.m-l70 { margin-left: 70px; }
.m-l80 { margin-left: 80px; }
.m-l90 { margin-left: 90px; }
.m-l100 { margin-left: 100px; }
/*=================  
	Margin Right
====================*/
.m-r0 { margin-right: 0;}
.m-r5 { margin-right: 5px; }
.m-r10 { margin-right: 10px;}
.m-r15 { margin-right: 15px; }
.m-r20 { margin-right: 20px; }
.m-r30 { margin-right: 30px; }
.m-r40 { margin-right: 40px; }
.m-r50 { margin-right: 50px; }
.m-r60 { margin-right: 60px; }
.m-r70 { margin-right: 70px;}
.m-r80 { margin-right: 80px; }
.m-r90 { margin-right: 90px; }
.m-r100 { margin-right: 100px;}
/*=================  
	Margin Left Right
====================*/
.m-lr0 { margin-left: 0; margin-right: 0; }
.m-lr5 { margin-left: 5px; margin-right: 5px; }
.m-lr10 { margin-left: 10px; margin-right: 10px; }
.m-lr15 { margin-left: 15px; margin-right: 15px; }
.m-lr20 { margin-left: 20px; margin-right: 20px; }
.m-lr30 { margin-left: 30px; margin-right: 30px; }
.m-lr40 { margin-left: 40px; margin-right: 40px; }
.m-lr50 { margin-left: 50px; margin-right: 50px; }
.m-lr60 { margin-left: 60px; margin-right: 60px; }
.m-lr70 { margin-left: 70px; margin-right: 70px; }
.m-lr80 { margin-left: 80px; margin-right: 80px; }
.m-lr90 { margin-left: 90px; margin-right: 90px; }
.m-lr100 { margin-left: 100px; margin-right: 100px; }
/*=================  
	Margin Top Bottom
====================*/
.m-tb0 { margin-bottom: 0; margin-top: 0; }
.m-tb5 { margin-bottom: 5px; margin-top: 5px; }
.m-tb10 { margin-bottom: 10px; margin-top: 10px; }
.m-tb15 { margin-bottom: 15px; margin-top: 15px; }
.m-tb20 { margin-bottom: 20px; margin-top: 20px; }
.m-tb30 { margin-bottom: 30px; margin-top: 30px; }
.m-tb40 { margin-bottom: 40px; margin-top: 40px; }
.m-tb50 { margin-bottom: 50px; margin-top: 50px; }
.m-tb60 { margin-bottom: 60px; margin-top: 60px; }
.m-tb70 { margin-bottom: 70px; margin-top: 70px; }
.m-tb80 { margin-bottom: 80px; margin-top: 80px; }
.m-tb90 { margin-bottom: 90px; margin-top: 90px; }
.m-tb100 { margin-bottom: 100px; margin-top: 100px; }
/*=================  
	Box Max Width Css
====================*/
.mw50 { max-width: 50px; } 
.mw60 { max-width: 60px; } 
.mw80 { max-width: 80px; } 
.mw100 { max-width: 100px; } 
.mw200 { max-width: 200px; } 
.mw300 { max-width: 300px; } 
.mw400 { max-width: 400px; } 
.mw500 { max-width: 500px; } 
.mw600 { max-width: 600px; } 
.mw700 { max-width: 700px; } 
.mw800 { max-width: 800px; } 
.mw900 { max-width: 900px; } 
.mw1000 { max-width: 1000px; }
/*=================  
	Positions Z-index
====================*/
.posi-ab { position: absolute; }
.posi-rl { position: relative; }
.posi-st { position: static; }
.zi1 { z-index: 1; }
.zi2 { z-index: 2; }
.zi3 { z-index: 3; }
.zi4 { z-index: 4; }
.zi5 { z-index: 5; }
.zi6 { z-index: 6; }
.zi7 { z-index: 7; }
.zi8 { z-index: 8; }
.zi9 { z-index: 9; }
.zi10 { z-index: 10; }
.zi100 { z-index: 100; }
.zi999 { z-index: 999; }
/*=================  
	Text Color
====================*/
.text-white { color: #fff; }
.text-black { color: #000; }
.text-gray { color: #f5f6f6; }
.text-red { color: #FF4045; }
.text-yellow { color: #ffb822; }
.text-pink{ color: #ff3c85; }
.text-blue{ color: #00c5dc; }
.text-green{ color: #34bfa3; }
.text-orange{  color: #ef9800; }
/*=================  
	Text Color
====================*/
.bg-tp { background-color: transparent; }
.bg-primary { background-color: ; }
.bg-white { background-color: #FFF; }
.bg-black { background-color: #000; }
.bg-gray { background-color: #F7F9FB; }
.bg-red { background-color: #FF4045; }
.bg-green { background-color: #34bfa3; }
.bg-yellow { background-color: #ffb822; }
.bg-pink { background-color: #ff3c85; }
.bg-blue { background-color: #00c5dc; }
.bg-orange { background-color: #ef9800; }
/*=================  
	Background White Transperent
====================*/
.tpbw1{ background-color:rgba(255,255,255,0.1) }
.tpbw2{ background-color:rgba(255,255,255,0.2) }
.tpbw3{ background-color:rgba(255,255,255,0.3) }
.tpbw4{ background-color:rgba(255,255,255,0.4) }
.tpbw5{ background-color:rgba(255,255,255,0.5) }
.tpbw6{ background-color:rgba(255,255,255,0.6) }
.tpbw7{ background-color:rgba(255,255,255,0.7) }
.tpbw8{ background-color:rgba(255,255,255,0.8) }
.tpbw9{ background-color:rgba(255,255,255,0.9) }
/*=================  
	Background Black Transperent
====================*/
.bg-tpb1{ background-color:rgba(0,0,0,0.05) }
.bg-tpb2{ background-color:rgba(0,0,0,0.2) }
.bg-tpb3{ background-color:rgba(0,0,0,0.3) }
.bg-tpb4{ background-color:rgba(0,0,0,0.4) }
.bg-tpb5{ background-color:rgba(0,0,0,0.5) }
.bg-tpb6{ background-color:rgba(0,0,0,0.6) }
.bg-tpb7{ background-color:rgba(0,0,0,0.7) }
.bg-tpb8{ background-color:rgba(0,0,0,0.8) }
.bg-tpb9{ background-color:rgba(0,0,0,0.9) }


/*---------------------------------------------------------------
27. PAGE-CONTENT
---------------------------------------------------------------*/
.heading-bx {
    margin-bottom: 50px;
}
.heading-bx.no-margin {
    margin-bottom: 0;
}
.heading-bx h1,
.heading-bx h2,
.heading-bx h3 {
    margin-top: 0;
}
.title-small {
    display: block;
    color: #494949;
    margin-bottom: 15px;
}
.heading-bx p {
	padding-top: 10px;
    font-size: 18px;
}
.text-center.heading-bx p {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}
.text-white {
    color: #FFF;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
    color: #FFF;
}
.mfp-title{
	display:none;
}
.list-inline li{
	display: inline-block;
}
.mfp-gallery{
	list-style:none;
	margin-bottom: 0;
}
/*= Section Content Part =*/
@media only screen and (max-width: 1024px) {
    .section-area {
        background-attachment: scroll !important;
    }
}
@media only screen and (max-width: 991px) {
    .page-content {
        margin-top: 0;
    }
}
@media only screen and (max-width: 767px) {
	.heading-bx {
		margin-bottom: 40px;
	}	
}